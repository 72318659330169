
.mothers {
  background-color: rgb(248, 187, 204);
  background-image: url('../../images/mothers_back.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 30px 0px;
}

.mothers__flex {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 768px) {
    gap: 60px;
    flex-wrap: nowrap;
  }
}

.mothers__fakeVideo {
  display: block;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.mothers__fakeVideo img {
  display: block;
  max-width: 100%;

}

.mothers__txt {
    width: 100%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.mothers__txt h2 {
    color: #2a5180;
    font-family: var(--font-orenament-bounce);
    font-weight: 700;
    font-size: 2.3rem;
    line-height: 1.2;
    margin: 0px 0px 10px 0px;
    @media screen and (min-width: 650px) {
      font-size: 3.3rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 3.3rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 4.3rem;
    }
}

.mothers__txt p {
    color: var(--color_white);
    font-weight: 400 !important;
    font-size: 1.6rem;
    margin: 0;
    @media screen and (min-width: 650px) {
      font-size: 1.8rem;
    }
    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.275rem;
    }
}
