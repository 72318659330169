/* article */
.article__header h1 {
  font-family: var(--font-orenament);
  font-size: 2.375rem;
  margin: 1.375rem 0;
  line-height: 2rem;
  @media screen and (min-width: 650px) {
    font-size: 3.375rem;
    margin: 2.375rem 0;
    line-height: 3rem;
  }
}

.article_pm {
  background-color: var(--color_blue_brightSky);
  padding: 7px 0px;
  margin-bottom: $basicGap;
}

.article_pm .container {
  display: flex;
  gap: 0px;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (min-width: 450px) {
    flex-wrap: nowrap;
    gap: 10px;
  }
}

.article_pmRing {
  width: 114px;
  height: 100px;
  background-image: url("../../images/managerRing.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.article_pmRing img {
  width: 79.945%;
  height: auto;
  border-radius: 50%;
  position: relative;
  left: 16.249%;
  top: 3.971%;
}

.article_pmTxt {
  width: 100%;
  @media screen and (min-width: 450px) {
    flex: 1;
    width: auto;
  }
}

.article_pmTxt h2 {
  font-family: var(--font-orenament);
  margin: 0 0 0rem 0;
}
.article_pmTxt p {
  margin: 0;
  font-size: 0.9rem;
}

.article_image {
  margin-bottom: $basicGap;
}

.article_image img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0px auto;
}

.article__paragraph {
  margin-bottom: $basicGap;
  color: var(--color_txt);
  font-size: 1rem;
  line-height: 1.6rem;
  @media screen and (min-width: 650px) {
    font-size: 1.2rem;
  line-height: 1.8rem;
  }

  h2, h3, h4, h5, h6 {
    font-family: var(--font-orenament);
    color: #000000;
    margin-bottom: 10px;
  }

  a {
    color: var(--color_txt);
    text-decoration: underline;
    font-weight: bold;
    font-family: 'GothamMedium';
    &:hover {
      color: var(--color_fp_red);
    }
  }
  b {
    font-weight: bold;
    font-family: 'GothamMedium';
  }
}

.article__paragraph p {
  margin: 0px 0px 10px 0px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.article__paragraph img {
  max-width: 100%;
  height: auto;
}


.article__btn {
  margin: 0px auto $basicGap auto;
  text-align: center;
}

.article__btn a {
  font-family: var(--font-orenament);
  background-color: var(--color_fp_red);
  color: var(--color_white);
  display: inline-block;
  padding: 10px 25px;
  border-radius: 15px;
  margin: 0px auto;
  text-decoration: none;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0) 0px 8px 24px;
  @media screen and (min-width: 650px) {
    font-size: 2rem;
    padding: 15px 45px;
    border-radius: 25px;
  }
  &:hover {
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  }
}

.article__btn p {
  text-align: center;
  margin: 0;
  padding: 0;
}

.article__ruler {
  margin-bottom: 20px;
}

.article__video {

    margin: 0px auto $basicGap auto;

}
.article__video > div {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

.article__elist {
    margin-bottom: $basicGap;
}

.article__elist h2 {
  font-family: var(--font-orenament);
  margin-bottom: 0px 0px 20px 0px;
}
.article__elist h3 {
  margin-bottom: 0px 0px 0px 0px;
}
.article__elistHeader {
  max-width: 800px;
  margin-bottom: 40px;
}
.article__elistItems {
  background-color: var(--category_color_second);
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (min-width: 650px) {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.article__elistItemsFlex {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 880px) {
    flex-wrap: nowrap;
  }
}

.article__elistItem {
  color: var(--color_greyDark);
  width: 100%;
  @media screen and (min-width: 450px) {
    width: calc((100% - 20px) / 2);
  }
  @media screen and (min-width: 880px) {
    flex: 1;
    width: auto;
  }
}

.article__elistItem h4 {
  color: var(--color_greyDark);
  font-size: 1.6rem;
  padding: 0;
  margin: 0px 0px 15px 0px;
  font-family: var(--font-orenament);
  @media screen and (min-width: 650px) {
    margin: 0px 0px 10px 0px;
  }
}

.article__elistItem img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 15px;
}
.article__elistItem p {
  margin: 0px 0px 15px 0px;
  &:last-child {
    margin: 0px 0px 0px 0px;
  }
}
.article__elistItem a {
    color: var(--color_greyDark);
    text-decoration: underline;
    font-weight: bold;
    font-family: 'GothamMedium';
    &:hover {
      color: var(--color_fp_red);
    }
}
.aricle__container {
  max-width:  896px;
}

p.text-center {
  text-align: center;
}

p.text-right {
  text-align: right;
}

.article__paragraph h2 {
  font-size: 2.2rem;
  margin-bottom: 15px;
  margin-top: 0px;
}

.article__paragraph h3 {
  font-size: 2rem;
  margin-bottom: 15px;
  margin-top: 0px;
}

.article__paragraph h4 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  margin-top: 0px;

}

.article__paragraph h5, .article__paragraph h6 {
  font-size: 1.6rem;
  margin-bottom: 15px;
  margin-top: 0px;
  color: var(--category_color_first);
}

.article__paragraph h6 {
  color: var(--category_color_second);
}
