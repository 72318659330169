
footer {
  margin-top: $basicGap;
}

.foot__in {
  background-color: #dcdcdc !important;
}

.foot__waveWrap {
  height: 3rem;
  position: relative;
  overflow: hidden;
  //position: absolute !important;
}

.foot__waveWrap svg {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  top: -2rem;
  box-sizing: content-box;
  vertical-align: middle;
  width: 800px;
  color: #dcdcdc !important;
  @media screen and (min-width: 800px) {
    width: 100%;
  }
}

.foot__txt {
  padding-top: $basicGap;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  font-family: var(--font-orenament);
  font-weight: 400;
  font-size: 1.1rem;
  flex-wrap: wrap;
  @media screen and (min-width: 650px) {
    flex-wrap: nowrap;
  }
  @media screen and (min-width: 800px) {
    font-size: 1.6875rem;
  }
}

.foot__txt_adress {
  padding-top: 5px;
  display: block;
  font-family: var(--font-txt);
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.3rem;
  @media screen and (min-width: 800px) {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

.foot__logo {
  width: 53px;
  height: auto;
}

.foot__txt > div {
  width: 100%;
  text-align: center;
  @media screen and (min-width: 650px) {
    width: auto;
    text-align: left;
  }
}

.foot__const {
  display: flex;
  width: auto;
  gap: $basicGap;
  list-style-type: none;
  margin: 0;
  padding: $basicGap 0 $basicGap 0;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media screen and (min-width: 650px) {
    flex-wrap: nowrap;
  }
}

.foot__const li {
  position: relative;
  width: 100%;
  text-align: center;
  @media screen and (min-width: 650px) {
    width: auto;
  }
  &::after {
      content: "";
      position: absolute;
      right: -15px;
      top: 2px;
      width: 1px;
      height: 15px;
      background-color: var(--color_txt);
      display: none;
      @media screen and (min-width: 650px) {
        display: block;
      }
  }
  &:last-child::after {
    display: none;
  }
}

.foot__const a, .foot__const button {
    background: none;
    border: 0;
    text-decoration: none;
    color: var(--color_txt);
    cursor: pointer;
}
