/**/
.list__grid_1_2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: auto;
  gap: calc($basicGap / 2);
  @media screen and (min-width: 450px) {
    grid-template-columns: 1fr 1fr;
    gap: $basicGap;
  }
}

.list__grid_1_3 {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: auto;
  gap: calc($basicGap / 2);
  @media screen and (min-width: 450px) {
    grid-template-columns: 1fr 1fr;
    gap: $basicGap;
  }
  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: $basicGap;
  }
}

.list__grid_1_5 {
  display: grid;
  grid-template-columns: 1fr;
  grid-area: auto;
  gap: calc($basicGap / 2);
  @media screen and (min-width: 450px) {
    grid-template-columns: 1fr 1fr;
    gap: $basicGap;
  }
  @media screen and (min-width: 650px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: $basicGap;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $basicGap;
  }
  @media screen and (min-width: 990px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: $basicGap;
  }
}

.list__gridItem {
  font-size: 0.9rem;
  @media screen and (min-width: 650px) {
    font-size: 1rem;
  }
}

.list__gridItem h3 {
  font-family: var(--font-orenament);
  margin: 0px 0px 15px 0px;
  font-size: 1.5rem;
}

.list__gridItem img {
  margin: 0px 0px 15px 0px;
  display: block;
  height: auto;
  width: 100%;
}

.list__gridItem > div {
  margin: 0px 0px 15px 0px;
}

.list__gridItem a {
  text-decoration: none;
  color: var(--color_txt);
  transition: all 0.3s ease-in-out;
   img {
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0) 0px 8px 24px;
   }
   &:hover {
    transition: all 0.3s ease-in-out;
    color: var(--color_black);
    img {
      transition: all 0.3s ease-in-out;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
     }
   }
}
