/**/

.catStrip {
  max-width: 1320px;
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  @media screen  and (min-width: 650px){
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $basicGap;
  }
}

.catStrip__item {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0) 0px 8px 24px;
  border-radius: 50%;
  transform: rotate(0deg);
  &:hover {
    transition: all 0.3s ease-in-out;
    transform: rotate(-5deg);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
  }
}


.catStrip__item img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
  display: block;
}

.catStripH2 {
  font-family: var(--font-orenament);
  margin: 0px 0px 20px 0px;
  color: var(--color_fp_red);
}
