
.actionstrip {
  width: 100%;
  background-color: var(--color_grey);
  padding: 10px 0px;
  color: var(--color_white);
  text-align: center;
  box-sizing: border-box;
  position: relative;
  padding-right: 60px;
}

.actionstrip p {
  margin: 0;
}

.actionstrip a {
  color: var(--color_white);
  font-weight: bold;
  font-family: 'GothamMedium';
    &:hover {
      color: var(--color_black);
    }
}

.actionstrip__close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 7px;
  top: 4px;
  background-image: url('../../images/close.svg');
  background-size: 30px 30px;
  border: none;
  background-color: transparent;
  transition: all 0.5s linear;
  cursor: pointer;
  transform-origin: 50% 50%;
  &:focus, &:hover, &:active {
    outline: none;
  }
  &:hover {
    transition: all 0.3s linear;
    transform: rotate(180DEG);
  }
}
