:root {
  --color_back: rgb(248, 246, 245);
  --color_grey: rgb(128, 128, 128);
  --color_greyDark: rgb(51, 51, 51);
  --color_white: rgb(255, 255, 255);
  --color_black: rgb(0, 0, 0);
  --color_blue_brightSky: rgb(78, 203, 232);
  --color_fp_red: rgb(228, 0, 43);
  --color_txt: #333;
  --font-txt: "GothamBook", sans-serif;
  --font-orenament: "LetsBeRegular", sans-serif;
  --font-orenament-bounce: "LetsBeBouncy", sans-serif;
}

$basicGap: 30px;
