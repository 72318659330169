
@font-face {
  font-family: 'GothamBook';
  src: url('./../fonts/Gotham-Book.5b247eba.ttf') format('ttf'),
    url('./../fonts/Gotham-Book.0b64a870.eot') format('eot'),
    url('./../fonts/Gotham-Book.a42686e4.woff2') format('woff2'),
    url('./../fonts/Gotham-Book.e4e1d9ca.woff') format('woff');
  font-weight: 350;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('./../fonts/Gotham-Medium.5fb155e9.ttf') format('ttf'),
    url('./../fonts/Gotham-Medium.2f7f14e4.eot') format('eot'),
    url('./../fonts/Gotham-Medium.885e4b55.woff2') format('woff2'),
    url('./../fonts/Gotham-Medium.bb2f846b.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBlack';
  src: url('./../fonts/GothamBlack.e4e608c3.ttf') format('ttf'),
    url('./../fonts/GothamBlack.acd2fdfb.eot') format('eot'),
    url('./../fonts/GothamBlack.62f034aa.woff2') format('woff2'),
    url('./../fonts/GothamBlack.ff7ce841.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'LetsBeRegular';
  src: url('./../fonts/LetsBeGlyphs-Regular.93446fcd.ttf') format('ttf'),
    url('./../fonts/LetsBeGlyphs-Regular.fa5a8afb.eot') format('eot'),
    url('./../fonts/LetsBeGlyphs-Regular.5f696480.woff2') format('woff2'),
    url('./../fonts/LetsBeGlyphs-Regular.7c8bbcff.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LetsBeBouncy';
  src: url('./../fonts/LetsBeGlyphs-Bouncy.196897c2.ttf') format('ttf'),
    url('./../fonts/LetsBeGlyphs-Bouncy.2983d60a.eot') format('eot'),
    url('./../fonts/LetsBeGlyphs-Bouncy.2bbd09ec.woff2') format('woff2'),
    url('./../fonts/LetsBeGlyphs-Bouncy.f32ea35f.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
