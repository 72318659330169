
$menuToggle: 650px;

.header {
  position: relative;
  margin-bottom: 25px;
  z-index: 10000000;
  @media screen and (min-width: $menuToggle) {
    display: flex;
    margin-bottom: 45px;
  }
}


.header__logo {
  width: 115px;
  height: auto;
}

.header__nav {

  position: relative;
  @media screen and (min-width: $menuToggle) {
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.header__navMain {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @media screen and (max-width: $menuToggle - 1px) {
    position: absolute;
    width: 170px;
    right: -200px;
      top: -49px;
    padding: 10px;
    background-color: var(--color_white);
    transition: right 0.5s ease-in-out;
  }

  @media screen and (min-width: $menuToggle) {
    display: flex;
    gap: 30px;
  }
}

.header__navMain > li {
  position: relative;
  @media screen and (max-width: $menuToggle - 1px) {
    margin-bottom: 10px;

  }
}



.header__navMain a {
  font-family: LetsBeRegular;
  color: var(--color_black);
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
  &:hover {
      transition: color 0.3s ease-in-out;
      color: var(--color_fp_red);
  }
}

.header__navMain > li:hover {
  .header__navSub {
    @media screen and (min-width: $menuToggle) {
      display: block;
    }
  }
}

.header__nav .active > a {
  color: var(--color_fp_red);
}

.header__navSub  {
  background-color: var(--color_white);
  margin: 0;
    padding: 15px;
    list-style-type: none;

  @media screen and (min-width: $menuToggle) {
    transition: all 0.5s ease-in-out;
    position: absolute;
    display: none;
    margin-top: 20px;
    top: 0px;
    left: 0px;
    width: 150px;
    text-align: left;
    box-shadow: 0px 0px 28px -14px rgba(0,0,0,0.47);
  }
  &:hover {
    display: block;
  }
}
.header__navSub li {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }
}

.header__menuToogle {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  right: -13px;
  top: -43px;
  background-image: url('../../images/menu_on.svg');
  background-size: 40px 40px;
  border: none;
  background-color: var(--color_white);
  transition: right 0.5s ease-in-out;
  cursor: pointer;
  transform-origin: 50% 50%;
  &:focus, &:hover, &:active {
    outline: none;
  }
  &:hover {
    transition: right 0.5s ease-in-out;
  }
  @media screen and (min-width: $menuToggle) {
    display: none;
  }
}

.menuOpen {
  .header__menuToogle {
    background-image: url('../../images/menu_off.svg');
    right: 157px;
  }
  .header__navMain {
    @media screen and (max-width: $menuToggle - 1px) {
      right: -13px;
      transition: right 0.5s ease-in-out;
    }
  }
}
