
/* carousel */
.hp__slider {
  background-color: rgba(237,237,237,0.8);
  margin-bottom: $basicGap;
  position: relative;
}

.swiper-slide img {
  width: 100%;
  height: auto;
  display: block;
}
.swiperhp {
  overflow: hidden;
}
.hp__slider .swiper-button-prev, .hp__slider .swiper-button-next, .hp__slider .swiper-button-prevhp, .hp__slider .swiper-button-nexthp {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 100000;
  top: 50%;
  &::after {
    display: block;
    width: 40px;
    height: 40px;
    content: "";
    background-image: url('../../images/arrow.svg');
    background-size: 40px 40px;
    background-repeat: no-repeat;
  }
}
.hp__slider .container {
  position: relative;
}
.hp__slider .swiper-button-prev, .hp__slider .swiper-button-prevhp {
  left: 30px;
  @media screen and (min-width: 1570px) {
    left: -60px;
  }
}
.hp__slider .swiper-button-next, .hp__slider .swiper-button-nexthp {
  right: 30px;
  @media screen and (min-width: 1570px) {
    right: -60px;
  }
}
.hp__slider .swiper-button-prev::after, .hp__slider .swiper-button-prevhp::after {
  transform: rotate(180DEG);

}

.hp__slider .swiper-button-lock {
  display: block !important;
}
.heureka {
  position: relative;
}
.heureka h2 {
  font-family: var(--font-orenament);
  margin-bottom: 0px 0px 20px 0px;
  padding-right: 100px;
}
.heureka .swiper-slide a {
  text-decoration: none;
  cursor: pointer;
  color: var(--color_grey);
  font-family: 'GothamBlack';
  transition: color 0.3s ease-in-out;
  &:hover {
    transition: color 0.3s ease-in-out;
    color: var(--color_fp_red);
  }
}

.heureka .swiper-button-prevPr, .heureka .swiper-button-nextPr {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 16px;
  z-index: 100000;

  &::after {
    display: block;
    width: 36px;
    height: 36px;
    content: "";
    background-image: url('../../images/arrowSmall.svg');
    background-size: 36px 36px;
    background-repeat: no-repeat;
  }
}
.heureka .swiper-button-prevPr {
  right: 60px;
  left: auto;
  transform: rotate(180deg);
}

.heureka .swiper-button-nextPr {
  right: 16px;
  left: auto;
}
