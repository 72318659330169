
html {
  overflow-x: hidden;
}

body {
  font-family: 'GothamBook';
  background-color: var(--color_back);
  background-image: url('../../images/back.jpg');
  background-position: top;
  background-size: 100% auto;
  overflow-x: hidden;
  min-height: 100vh;
  @media screen and (min-width: 1920px) {
    background-size: 1920px auto;
  }

  & * {
    box-sizing: border-box;
  }
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  padding-left: 16px;
  padding-right: 16px;
}
.container__thin {
  max-width: 1200px;
}

.waveWrapper {
  width: 100%;
  overflow: hidden;
  height: 3rem;
}
.waveWrapperSmall {
  width: 100%;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  height: 3rem;
}
.wave {
  height: 3rem;
  margin-left: -12.7% !important;
  width: 170%;
  min-width: 5007px;

  &--orange {
    color: #f59f08 !important;
  }
}

.wave--color {
  color: var(--category_color_first);
}

.mb {
  margin-bottom: $basicGap;
}
.mt {
  margin-top: $basicGap;
}
.pb {
  padding-bottom: $basicGap;
}
.pt {
  padding-top: $basicGap;
}
.owh {
  overflow: hidden;
}
